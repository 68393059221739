// 课程状态
export const COURSE_STATUS = {
    // 未购买
    UN_BOUGHT: 1,
    // 已购买
    BOUGHT: 2,
    // 未购买&直播中
    UN_BOUGHT_LIVE: 3,
    // 已购买&直播中
    BOUGHT_LIVE: 4,
    // h5广告
    H5_AD_LINK: 5
};
// 淘金source 详见需求wiki https://wiki.baijia.com/pages/viewpage.action?pageId=145515095
export const TAOJING_SOURCE = {
    // 官网公开课专区-课程卡片-跳转详情页
    OPENCOURSE_JUMP_DETAIL: 'tj165150107336985600z',
    // 官网公开课专区-免费课一键报名
    OPENCOURSE_ORDER: 'tj165150107414569984z',
    // 官网品类卡片-热门课程卡片-跳转详情页
    HOTCOURSE_JUMP_DETAIL: 'tj165150107484820480z',
    SYSTEMCOURSE_JUMP_DETAIL: 'tj165150107554039808z'
};

// 跳转选课页来源
export const CATEGORY_SOURCE = {
    filter: '首页品类筛选',
    personalCenter: '首页右上角选课中心按钮',
    openCourse: '首页公开体验课模块了解更多',
    systemCourse: '首页正价课模块了解更多',
    header: '顶导选课中心'
};

// 高途pc-帮助中心文档配置 hydra 链接
export const HYDRA_URL_HELP_CENTER = `https://hydra-cdn.gsxcdn.com/activity-fe/5998378541511063352.json?datetime=${Date.now()}`;
// 途途pc-帮助中心文档配置 hydra 链接
export const HYDRA_URL_TUTU_HELP_CENTER = `https://hydra-cdn.gsxcdn.com/activity-fe/5998378541511063352.json?datetime=${Date.now()}`;
// 高途pc 动态换肤
export const HYDRA_URL_CHANGE_SKIN = `https://hydra-cdn.gsxcdn.com/activity-fe/6085422100652117591.json?datetime=${Date.now()}`;
// 精品pc 动态换肤
export const HYDRA_URL_CHANGE_SKIN_GTGZ = `https://hydra-cdn.gsxcdn.com/activity-fe/6097818690118732667.json?datetime=${Date.now()}`;
// hydra 老版跟谁学跳转高途
export const HYDRA_URL_JUMP_URL = `https://hydra-cdn.gsxcdn.com/activity-fe/5968609528175504909.json?datetime=${Date.now()}`;
// 帮助中心 摇光通用链接
export const HELP_CENTER_URL = 'm.gaotu.cn/gen-page/link';
// 帮助中心 摇光通用链接-精品
export const HELP_CENTER_URL_GTGZ = 'm.gtgz.cn/gen-page/link';
// 首页资讯，时间展示
export const TIME_DIFFERENCE = 3600000;
// 首页资讯，左侧展示资讯数量
export const INFORMATION_LEFT_NUM = 5;
// 首页资讯，换一换最大页数
export const MAX_PAGE = 3;
// 首页资讯列表，品类宽度
export const CATEGORY_SWIPER_WIDTH = 1136;
// 首页资讯，一页展示资讯数量
export const PAGE_SIZE = 11;
// 资讯防抖时间
export const DEBOUNCE_TIME = 200;
// 首页资讯、品类最大字符数
export const MAX_CATEGORY_NAME_LENGTH = 8;

// 首页曝光埋点
export const EVENT_ID_HOME_PAGEVIEW = 9268082668431360;
// 首页品类点击
export const EVENT_ID_CATEGORY_CLICK = 9268116627023872;
// 首页品类筛选展开筛选展开卡片曝光
export const EVENT_ID_CATEGORYCARD_VIEW = 9268120519993344;
// 首页品类筛选模块滑动
export const EVENT_ID_CATEGORY_SCROLL = 9268124244600832;
// 首页品类筛选卡片热门课程展示
export const EVENT_ID_HOTCOURSE_VIEW = 9268130048468992;
// 高途官网-课程品类展开卡片热门课程点击
export const EVENT_ID_HOTCOURSE_CLICK = 9268136709613568;
// 首页-banner广告曝光
export const EVENT_ID_BANNER_VIEW = 9268177844791296;
// 高途PC官网首页-兜底广告曝光
export const EVENT_ID_BANNER_DEFALUT_VIEW = 9285678859446272;
// 高途PC官网首页-兜底广告点击
export const EVENT_ID_BANNER_DEFAULT_CLICK = 9285683468986368;
// 首页-banner广告点击
export const EVENT_ID_BANNER_CLICK = 9268182435194880;
// 高途官网首页-公开课模块课程卡片曝光
export const EVENT_ID_OPENCOURSE_VIEW = 9268188842452992;
// 高途官网首页-公开课模块课程卡片点击
export const EVENT_ID_OPENCOURSE_CLICK = 9268192907454464;
// 高途官网首页-公开课模块课程卡片一键报名按钮点击
export const EVENT_ID_OPENCOURSE_BUTTON_CLICK = 9268201834506240;
// 高途官网-公开体验课模块-查看更多按钮点击
export const EVENT_ID_OPENCOURSE_MORE_CLICK = 9268217261811712;
// 高途官网-正价课模块曝光
export const EVENT_ID_SYSTEMCOURSE_VIEW = 9268234658146304;
// 高途官网-正价课模块-查看更多按钮点击
export const EVENT_ID_SYSTEMCOURSE_MORE_CLICK = 9268236040955904;
// 高途官网-正价课模块-课程卡片点击
export const EVENT_ID_SYSTEMCOURSE_CLICK = 9268238290675712;
// 题库入口曝光事件
export const EVENT_ID_QUESTION_BANK_VIEW = 9487927770310656;
// 题库入口点事件
export const EVENT_ID_QUESTION_BANK_CLICK = 9487940597409792;
// 首页-资讯模块曝光
export const EVENT_ID_INFORMATION_VIEW = 10122000132237312;
// 资讯-品类切换按钮点击
export const EVENT_ID_INFORMATION_CATEGORY_CLICK = 10122004941400064;
// 资讯-换一换按钮点击
export const EVENT_ID_INFORMATION_REPLACE_CLICK = 10122006812125184;
// 资讯列表的资讯点击
export const EVENT_ID_INFORMATION_CLICK = 8992599469156352;
// 登录页 唤起
export const EVENT_ID_LOGIN_PAGE_VIEW = 10609820908349440;
// 登录成功
export const EVENT_ID_LOGIN_SUCCESS = 10178849772103680;
// PC客服引导弹窗曝光
export const EVENT_ID_SERVICE_MODEL_SHOW = 10734818636949504;
// PC客服引导弹窗关闭按钮点击
export const EVENT_ID_CLOSE_SERVICE_MODEL_CLICK = 10734819872958464;
// PC官网右侧浮窗按钮点击
export const EVENT_ID_MENU_CLICK = 10734652342888448;