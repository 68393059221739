import React, { createElement } from 'react';
import ReactDOM from 'react-dom';
import {judgeQuestionType} from './constants';
import '@gaotu-fe/exercise/dist/index.css';

// 初始化
export function bootstrap() {
}

// 挂载
export function mount(container, props) {
    ReactDOM.render(createElement(judgeQuestionType(props.type), {
        ...props,
        examType: props.type,
        questionId: props.id,
        isAutoGrading: 0,
        editable: false,
        showMyAnswer: false
    }, null), container);
}

// 更新
export function updated(attrName, value, container, props) {
    ReactDOM.render(createElement(judgeQuestionType(props.type), {...props}, null), container);
}

// 卸载
export async function unmount() {
}