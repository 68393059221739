import config from '~/environment.js';

let protocol = 'https:';
if (!process.server) {
    // eslint-disable-next-line prefer-destructuring
    protocol = window.location.protocol;

    // 背景：K9拆分，短时间内 gaotu100.com， gaotu.cn 并存
    // 兜底处理，如果在gaotu100.com 下，proxyUrl, pcUrl 接口的主域名要变成当前主域名
    const host = window.location.hostname;
    const prevDomain = 'gaotu100.com';
    const currDomain = 'gaotu.cn';

    if (host.includes(prevDomain)) {
        const configKeys = ['proxyUrl', 'pcUrl'];
        configKeys.forEach(key => {
            if (typeof config.env[key] === 'string' && config.env[key].includes(currDomain)) {
                config.env[key] = config.env[key].replace(currDomain, prevDomain);
            }
        });
    }
}

const BFF_BASE_URL = config.env.proxy ? 'bff' : protocol + config.env.bffProxyUrl;

const INTERACTIVE_BASE_URL = config.env.interactiveUrl;

let BASE_URL = '';
// 防止接口跨域因此用m.gaotu.cn
const PC_CORS_URL = protocol + config.env.pcUrl;
if (process.server) {
    BASE_URL = config.env.proxy ? '/yapi' : protocol + config.env.proxyUrl;
}
// 直连后端机器IP
else if (config.env.env === 'connectIP') {
    BASE_URL = 'connectIP';
}
else {
    BASE_URL = config.env.proxy ? '/yapi' : protocol + config.env.proxyUrl;
}

// eslint-disable-next-line import/no-commonjs
export default {
    courseComment: {
        pcFilter: `${BASE_URL}/courseComment/pc/filter`,
        pcList: `${BASE_URL}/courseComment/pc/list`,
    },
    // 暑假聚合页
    summer_welfare: {
        filter: `${BASE_URL}/fusionpage/filter`,
        list: `${BASE_URL}/fusionpage/list`,
    },
    banner: {
        // 首页banner列表
        list: `${BASE_URL}/banner/v2/list`
    },
    search: {
        // 热词获取
        getHotWords: `${BASE_URL}/v1/search/getHotWords`,
        // 课程搜索
        courseSearch: `${BASE_URL}/v1/search/courseSearch`,
        // 联想搜索
        suggestionSearch: `${BASE_URL}/v1/search/suggestionSearch`,
        // 关键词联想
        suggestionWords: `${BASE_URL}/v1/user_experience/search/suggestionWords`,
        // 搜索结果
        mainSearch: `${BASE_URL}/v1/user_experience/search/mainSearch`,
    },
    upload: {
        // 上传头像
        icon: `${BASE_URL}/upload/icon`
    },
    common: {
        // apllo 配置
        config: `${BASE_URL}/common/config`,
        // 隐私协议
        policy: `${BASE_URL}/common/privacy/policy/content`
    },
    course: {
        // 首页课程过滤
        filter: `${BASE_URL}/course/v7/pc/filter`,
        // 选课中心季节过滤
        subFilter: `${BASE_URL}/course/v7/pc/sub_filter`,
        // 课程列表
        list: `${BASE_URL}/course/v7/list`,
        // 课程详情
        detail: `${BASE_URL}/course/v7/detail`,
        // 子列表
        sublist: `${BASE_URL}/course/v7/sublist`,
        // 我的课程列表
        bought: `${BASE_URL}/v1/course/boughtListForPc`,
        // 试听视频链接
        tryListenUrl: `${BASE_URL}/course/mic/getExperienceClassVideo`,
        // 优惠信息接口
        promotionDetail: `${BASE_URL}/course/mic/promotion/v2/detail`,
        // 校验学生是否可以购买指定课程
        checkAccessRight: `${BASE_URL}/course/checkAccessRight`,
        // 课程详情页首屏接口
        courseDetailFirst: `${BASE_URL}/cs/api/product/course/detailFirst`,
        // 课程详情页全量接口
        courseDetailSecond: `${BASE_URL}/cs/api/product/course/detailSecond`,
        // 课程详情页报名状态接口
        courseDetailButton: `${BASE_URL}/cs/api/product/course/detailButton`,
        // 课程详情页课节列表分页
        totalChapter: `${BASE_URL}/cs/api/product/course/detail/totalChapter`,
        // 获取优惠信息
        coursePromotion: `${BASE_URL}/promotion/a/feign/promotion/app/getPromotionDetailNew`,
    },
    live: {
        // 跟谁学看回放
        playback: `${BASE_URL}/v1/live/playbackWeb`,
        // 类直播、即时视频
        videoLive: `${BASE_URL}/live/zplan/login/videoLive`,
        // 回放列表
        playback_list: `${BASE_URL}/live/zplan/playbackListWeb`,
        // 老看回放
        play: `${BASE_URL}/lecture/video/play`,
        // 跟谁学看直播
        login_web: `${BASE_URL}/v1/live/loginWeb`,
        // 老看直播
        login: `${BASE_URL}/gsx/lecture/live/login`,
        // 家长会
        parents_metting: `${BASE_URL}/v1/live/parentsMeeting`
    },
    order: {
        // 订单列表
        list: `${BASE_URL}/v1/order/list`,
        // 订单详情
        detail: `${BASE_URL}/v1/order/detail`,
        // 删除订单
        delete: `${BASE_URL}/v1/order/delete`,
        // 取消订单
        cancel: `${BASE_URL}/v1/order/cancel`,
        // 获取相应类型订单数量
        type: `${BASE_URL}/v1/order/getCount/byType`,
        // 班课报名
        canCreateOrder: `${BASE_URL}/v1/order/canCreateOrder`,
        // 创建订单
        create: `${BASE_URL}/v1/order/create`,
        // 零元课下单
        createForFreeCourse: `${BASE_URL}/web/order/create`,
        // 确认报名
        prepare: `${BASE_URL}/v1/order/prepare`,
        // 退款详情
        refund_detail: `${BASE_URL}/order/v4/refund_detail`,
        // 续班预报 支付尾款 重新购买
        balance: `${BASE_URL}/pay/v2/balance`,
        // 续班预报 定金退款
        refundDeposit: `${BASE_URL}refund/deposit`,
        // 单报和联报判断是否需要分层考试
        judgeIsNeedExamBeforePay: `${BASE_URL}/api/order/judgeIsNeedExamBeforePay`,
        // 校验学生是否可以购买指定课程
        checkCanBuy: `${BASE_URL}/web/order/specialClazz/checkCanBuy`,
        // 获取公开课报名成功以后的老师二维码 区分免费公开课和付费公开课
        getPublicClassQrCode: `${BASE_URL}/qr/code/getCode`
    },
    address: {
        // 地址列表
        list: `${BASE_URL}/user/address/v3/list`,
        // 增加地址
        add: `${BASE_URL}/user/address/v3/add`
    },
    user: {
        // 修改真实姓名
        saveRealName: `${BASE_URL}/v1/user/save/realName`,
        // 用户意见反馈
        feedback: `${BASE_URL}/v1/feedback/user/comment`,
        // 密码登录
        login: `${BASE_URL}/v1/user/login`,
        // 海外手机号区号
        countryOrRegionCode: `${BASE_URL}/user/area/countryOrRegion`,
        // 退出登录
        logout: `${BASE_URL}/v1/user/logout`,
        // 验证码登录
        loginSms: `${BASE_URL}/v1/user/loginSms`,
        // 找回密码（强密码校验）
        resetPassword: `${BASE_URL}/v1/user/newResetPassword`,
        // 修改密码（强密码校验）
        updatePassword: `${BASE_URL}/v1/user/newUpdatePassword`,
        // 修改手机号
        changeMobile: `${BASE_URL}/v1/user/changeMobile`,
        // 获取验证码
        sendPasscode: `${BASE_URL}/v1/user/sendPasscode`,
        // 用户信息
        profile: `${BASE_URL}/v1/user/profile`,
        // 用户额外信息
        extraProfile: `${BASE_URL}/v1/user/extraProfile`,
        // 更新用户信息
        updateProfile: `${BASE_URL}/v1/user/updateProfile`,
        // 用户学校
        school: `${BASE_URL}/v1/user/schools`,
        // 我的学币
        coin: `${BASE_URL}/v1/user/coinList`,
        // 个人资料页获取所有年级
        getAllGrades: `${BASE_URL}/v1/user/getAllGrades`,
        // 用户地址选择列表
        areaList: `${BASE_URL}/user/area/list`,
        // 获取个人中心地区
        getUserArea: `${BASE_URL}/user/userArea/get`,
        // 获取小程序码
        getWeixinCode: `${BASE_URL}/v1/user_experience/getWxaLoginQrCode`,
        // 获取用户扫码后的登录状态
        pollByKey: `${BASE_URL}/v1/user/scan/pollByKey`,
    },
    voucher: {
        // 优惠券列表
        voucher: `${BASE_URL}/coupon/app/userCoupons`,
        // 激活优惠券
        activate: `${BASE_URL}/coupon/active_progress`,
        courseList: `${BASE_URL}/coupon/course/list`
    },
    pay: {
        // 支付成功
        success: `${BASE_URL}/pay/v2/success/order`,
        // 再次支付
        repay: `${BASE_URL}/pay/repay`,
        // 支付成功课程轮询
        course: `${BASE_URL}/pay/v2/success/course`,
        // 支付
        pay: `${BASE_URL}/pay/v2/cashier_web`
    },
    study: {
        // 学习中心页面上方，最近7天直播和待办任务数量
        center: `${BASE_URL}/v1/study/center`,
        // 学习中心首页下方课程卡片
        courseCard: `${BASE_URL}/v1/study/courseCard`,
        // 获取学习中心我的课程的筛选项
        boughtFilterList: `${BASE_URL}/v1/course/boughtFilterListForPc`,
        // 我的课程详情
        coursedetail: `${BASE_URL}/course/v7/simple_detail`,
        // 日历直播列表
        calendar: `${BASE_URL}/v1/study/live/calendar`,
        // 每天的直播
        dayList: `${BASE_URL}/v1/study/listForDay`,
        // 任务列表
        task: `${BASE_URL}/v1/study/taskListForPc`
    },
    teacher: {
        info: `${BASE_URL}/teacher/mainInfo`,
        clazzList: `${BASE_URL}/v1/user_experience/teacher/v2/clazzList`,
        share: `${BASE_URL}/teacher/share`
    },
    service: {
        index: `${BASE_URL}/support/user/im/getRedirectImVendor`
    },
    famousTeacher: {
        config: `${BFF_BASE_URL}/teacherApi/famousList/getIndexPageList`,
        // 教师资质
        teacherList: `${BFF_BASE_URL}/teacherApi/teacher/showlist`,
    },
    coding: {
        getInstallPackage: `${BASE_URL}/coding/app/config/getByKey?key=app.download.info`
    },
    chunsun: {
        // 获取首页CMS下发数据
        getPage: `${BASE_URL}/v2/user_experience/page`,
        // 获取课程数据
        list: `${BASE_URL}/v2/user_experience/course/list`,
        // 获取首页banner数据
        bannerData: `${BASE_URL}/v2/user_experience/page/data/pcBanner`,
        // 跳转直播间
        jumpLive: `${INTERACTIVE_BASE_URL}/live/api/v1/live/loginWebForLive`,
        // 获取选课页品类筛选项
        filter: `${BASE_URL}/v1/user_experience/course/subject`,
        recommend: `${BASE_URL}/v2/user_experience/entry/recommend`,
        // 首页 资讯列表
        newsList: `${BASE_URL}/news-service/news/newsList`,
        // 资讯列表 首屏（品类+资讯）
        getNewsModuleData: `${BASE_URL}/v1/user_experience/news/getNewsModuleData`,
        // 获取资讯详情页数据
        getNewsDetail: `${BASE_URL}/v1/user_experience/news/getNewsDetail`
    },
    seo: {
        // 获取当前页面的title、description、keywords
        getTDK: `${BASE_URL}/v1/user_experience/seo/search`,
        // 获取题目信息
        getExercise: `${BASE_URL}/v1/user_experience/seo/queryPcExerciseSeoContent`,
        // 获取单题详细信息
        getTopic: `${BASE_URL}/v1/user_experience/seo/querySingleSeoQuestionDetail`,
        // 获取推荐公开课信息
        getExerciseCourseList: `${BASE_URL}/v2/user_experience/course/list`,
    }
};
