export const MODULE_CODE = {
    // 所有课程
    course: 'clazz_open_system',
    // 公开
    open: 'clazz_open',
    // 正价
    system: 'clazz_system',
    // 教师
    teacher: 'teacher_relate'
}

export const MODULE_NAME = {
    // 所有课程
    '-1': 'pc_normal',
    // 公开
    3: 'pc_class',
    // 正价
    4: 'pc_teacher',
}

// 官网搜索栏点击
export const EVENT_ID_SEARCH_CLICK = '10269448858789888';
// PC官网搜索流程进入（点击搜索按钮）
export const EVENT_ID_JUMP_SEARCH_RESULT = '10269459705915392';
// PC官网搜索结果页
export const EVENT_ID_SEARCH_RESULT_SHOW = '10269637012645888';
// PC官网结果页某模块点击 
export const EVENT_ID_SEARCH_RESULT_CARD_CLICK = '10269928041572352';
// PC官网结果页某模块曝光 
export const EVENT_ID_SEARCH_RESULT_CARD_VIEW = '10270049672325120';
// PC官网补空结果曝光 
export const EVENT_ID_SEARCH_RESULT_NO_DATA = '10270034992719872';
// PC官网搜索联想词点击 
export const EVENT_ID_RECOMMEND_WORD_CLICK = '10270102974392320';
// PC官网搜索联想词曝光 
export const EVENT_ID_RECOMMEND_WORD_VIEW = '10270111764473856';
// PC官网搜索历史删除按钮点击 	
export const EVENT_ID_DELETE_ALL_HISTORY = '10270140539824128';
// PC官网某条搜索历史删除点击 
export const EVENT_ID_DELETE_HISTORY = '10270144386197504';
// PC官网搜索框删除文本按钮点击
export const EVENT_ID_CLEAR_INPUT_DATA = '10399653653735424';