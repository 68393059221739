// 题目来源
export const EXERCISE_SOURCE = {
    1: '高途',
    2: '途途'
};
// 点击此处按钮点击
export const EVENT_ID_TO_EXERCISE_CLICK = '10027137530423296';
// 题库解析页面曝光
export const EVENT_ID_EXERCISE_PAGE_SHOW = '10027135058208768';
// 相关题库点击
export const EVENT_ID_RECOMMEND_EXERCISE_CLICK = '10146350088742912';
// 查看答案按钮点击
export const EVENT_ID_SEE_ANSWER = '10178747972151296';
// ip 限制弹窗曝光
export const EVENT_ID_LIMIT_MODEL_SHOW = '10178775981778944';
// 注册登录
export const EVENT_ID_LOGIN = '10178849772103680';
// 跳转课程详情页-source
export const TAOJIN_SOURCE = {
    SITEMAP_SOURCE: 'tj165150296405705728z',
    INFORMATION_SOURCE: 'tj165150107187020800z'
};
// 推荐课模块距离顶部距离
export const OPEN_COURSE_TOP_DISTANCE = 450;
// 推荐课程卡片所有间距高度
export const RECOMMEND_MARGIN_HEIGHT = 70;
// 节流时间
export const THROTTLE_TIME = 50;
export const PAGE_NAME = {
    'exercise-tools*': '多题页面',
    'topic*': '1题页面'
};