// 客服电话
export const CUSTOM_SERVICE_NUMBER = '400-0910-188';
// seo的description
export const DEFAULT_DESCRIPTION = '高途是一家科技教育公司，提供涵盖中小学生素质教育培训、大学教育培训、成人教育培训、职业教育培训和智能学习服务等。';
// 弹码轮询时，前端兜底的默认数据
export const GET_CODE_API_DEFAULT_DATA = {
    QRCode: 'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=gQG48TwAAAAAAAAAAS5odHRwOi8vd2VpeGluLnFxLmNvbS9xLzAycVIzVWdPVEpmbmkxMDAwME0wN2UAAgS4ublhAwQAAAAA',
    display: {
        bottom: '长按识别二维码或截图保存后打开微信识别二维码',
        title: '报名成功',
        top: '关注公众号，接收重要提醒'
    },
    source: 'pubQrcode'
};
// 登录后不刷新页面，执行回调函数的页面
export const LOGIN_NOREDIRECT_PATH_NAME = [
    'index',
    'category*',
    'category'
];

// windows 下载链接合集,对应32位和64位
export const WINDOWS_DOWNLOAD_URLS = {
    'GT_32': 'https://www.wenzaizhibo.com/default/home/liveclientDownload?partner_id=49752473&type=win_x86&businessCode=gaotu',
    'GT_64': 'https://www.wenzaizhibo.com/default/home/liveclientDownload?partner_id=49752473&type=win_x64&businessCode=gaotu',
    'TUTU_32': 'https://www.wenzaizhibo.com/default/home/liveclientDownload?partner_id=49752473&type=win_x86&businessCode=tutu',
    'TUTU_64': 'https://www.wenzaizhibo.com/default/home/liveclientDownload?partner_id=49752473&type=win_x64&businessCode=tutu',
    'GZ_32': 'https://www.wenzaizhibo.com/default/home/liveclientDownload?partner_id=49752473&type=win_x86&businessCode=guihaujingpin',
    'GZ_64': 'https://www.wenzaizhibo.com/default/home/liveclientDownload?partner_id=49752473&type=win_x64&businessCode=guihaujingpin'
}

// 路由白名单
export const ROUTE_WHITE_LIST = {
    aboutus: '1-aboutus',
    download: '2-download',
};

// ue服务名单
export const ueListKeywords = [
    '/v1/user/login',
    '/v1/user/loginSms',
    'user_experience'
];

// 网站是否置灰开关
export const GRAY_SWITCH = false;

// 屏蔽策略埋点统计 对应的页面类型
export const SHIELD_PAGE_TYPE = {
    // 首页
    HOME: 7,
    // 选课中心
    COURSE: 12,
    // 搜索页
    SEARCH: 13
};

export const P_CLIENT_TITLE = {
    1: '高途',
    2: '途途',
    8: '高中',
    12: '考研'
}
