import {
    Judge,
    Choice,
    Filling,
    Explain,
    Compound
} from '@gaotu-fe/exercise';

// 题目类型 1:单选题 2:多选题 3:不定项选择题 4:填空题 5:解答题 6:语音题 7:写作题 8:判断题 9:完形填空 10:复合体 11:多选多 12:视频题 13:字词句朗诵 14:古诗文朗诵 15:角色扮演"
export const judgeQuestionType = type => {
    switch (type) {
        case 1:
        case 2:
        case 3:
            return Choice;
        case 4:
            return Filling;
        case 5:
        case 7:
            return Explain;
        case 8:
            return Judge;
        case 9:
        case 10:
            return Compound;
        default:
            return Judge;
    }
};