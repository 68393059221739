/**
 * 手机号校验规则
 * 校验手机号, 如果是国外手机号（ 带区号）， 规则为 最少7位 最多11位（延续之前客户端的规则 扩大至20位）； 如果是国内手机号 就按照已有规则 /^[1][2,3,4,5,6,7,8,9][0-9]{9}$/校验
 */

export const FOREIGN_MOBILE_PREFIX = '00';
export const CHINESE_MOBILE_REGEXP = /^1[23456789]\d{9}$/;
// 国外手机号00 + 区号（1～4位） + 手机号 7-11位
export const FOREIGN_MOBILE_REGEXP = /^00\d{8,24}$/;
export const FOREIGN_MOBILE_REGEXP_WITHOUT_REGION_CODE = /^\d{7,20}$/;
export const CHINESE_MOBILE_MAX_LENGTH = 11;
export const FOREIGN_MOBILE_MAX_LENGTH = 20;
